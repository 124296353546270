<template>
  <v-row justify="center" align="start" class="mt-3">
    <v-col cols="2" md="1" align-self="center" >
      <v-img src="/img/logo_DaTA.png" max-height="100" contain />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ImgFooter",
};
</script>

<template>
  <div>
    <!-- BANNER BOX -->
    <v-row no-gutters v-show="msgBanner">
      <v-col cols="10" offset="1">
        <v-alert
          prominent
          dense
          :type="msgType"
          v-model="msgBanner"
          class="mt-2"
        >
          <v-row align="center">
            <v-col class="grow" style="word-break: keep-all">
              {{ msg }}
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <!-- NOT LOGGED IN -->
    <v-row no-gutters class="mt-0">
      <v-col cols="12">
        <v-card flat class="text-center pa-4">
          <v-card-title
            class="justify-center text-h4"
            style="word-break: keep-all"
          >
            Bienvenue sur le site de vote en ligne ENSTA Paris.
          </v-card-title>
          <v-card-subtitle class="justify-center pb-0">
            Vous n'êtes actuellement pas connecté.
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <p>
                <v-btn
                  elevation="1"
                  color="accent"
                  width="300"
                  class="pa-5"
                  @click="login"
                >
                  Se connecter
                </v-btn>
              </p>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ImgFooter></ImgFooter>
    <v-footer app>
      <v-row justify="center" align="start">
        <v-col cols="8" class="text-center">
          <span>
            Hosted and Developed with <v-icon>mdi-heart</v-icon> by
            <a target="_blank" class="hide_link" href="https://data-ensta.fr"
              >DaTA</a
            >
          </span>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { loginCheck } from "@/utils/login";
import ImgFooter from "@/components/ImgFooter";

export default {
  name: "Login",
  components: { ImgFooter },
  data() {
    return {
      msgBanner: false,
      msgType: "info",
      msg: "",
    };
  },
  props: {
    to: {
      type: String,
      default: "/",
    },
  },
  methods: {
    fail(err) {
      this.banner = {};
      if (err) {
        this.msgBanner = true;
        this.msgType = "error";
        this.msg = err;
      }
    },
    login() {
      loginCheck(this.to, (x, err) => {
        if (err) {
          this.fail(err);
        } else {
          this.$router.push(x);
        }
      });
    },
  },
  mounted() {
    if (this.$route.query.err) {
      this.fail(this.$route.query.err);
    }
  },
  watch: {
    $route(to) {
      if (to.query.err) {
        this.fail(to.query.err);
      }
    },
  },
};
</script>
